<!-- 我的IC卡 -->
<template>
  <div class="ic">
    <div class="ic-empty" v-if="!ic_show">
      <div @click="add">
        <img
          class="img"
          src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/%E7%BB%84%20881%403x.png"
        />
        <div class="ic-add">
          <i class="iconfont t-icon-tianjia"></i>添加IC卡
        </div>
        <p class="tip">快来添加第一张IC卡吧～</p>
      </div>
    </div>
    <div class="ic-info" v-else>
      <div class="add-box" @click="add" v-if="ic_list.length > 0">
        <div class="ic-add">
          <i class="iconfont t-icon-tianjia"></i>添加IC卡
        </div>
      </div>
      <div class="ic-list">
        <div class="ic-item" v-for="(item, index) in ic_list" :key="index">
          <p class="card">
            卡号 <span>{{ item.ic_num }}</span>
          </p>
          <p class="time">绑定日期：{{ item.created_at }}</p>
          <p class="unbind" @click="unbind(item)">解绑</p>
        </div>
      </div>
    </div>
    <!-- 添加ic卡 -->
    <van-dialog v-model="show_add"  title="添加IC卡" show-cancel-button confirmButtonColor="#3DC48D" @confirm="add_sure">
      <div class="card-dialog">
        <p class="title">输入卡号</p>
        <input type="number" class="card" id="card" v-model="card" placeholder="请输入卡号" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import { postData, getData } from "@/api/user";
import { setTimeout } from 'timers';
export default {
  data() {
    return {
      ic_list: [],
      ic_show:true,
      card_num: "", //解绑卡号
      show_add: false,
      card:"",
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#fff");
  },
  created() {
    this.list()
  },
  methods: {
    list () {
      getData({
        url: "api/charging/user/my_card",
        data: {},
      }).then((res) => {
        var data = res.data.data.data;
        if (res.data.code === 200) {
          this.ic_list = data;
          this.ic_show = data.length > 0
        }
      });
    },
    add() {
      this.card = ""
      this.show_add = true
    },
    add_sure () {
      postData({
        url: "api/charging/user/add_ic_card",
        data: {
          card_num: this.card,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success({
            message: res.data.data.msg,
            forbidClick: true,
            duration: 0,
          });
          setTimeout(()=>{
            location.reload()
          },1000)
        } else {
          this.$toast(res.data.data.msg)
        }
      });
    },
    unbind(item) {
      this.card_num = item.ic_num;
      Dialog.confirm({
        title: "解绑IC卡",
        message: `<p class="unbind-msg">确认解除该卡的绑定\n<span class="card">卡号：<i class="num">${this.card_num}</i></span></p>`,
        confirmButtonColor: "#3DC48D",
      })
        .then(() => {
          postData({
            url: "api/charging/user/un_bunding_ic",
            data: {
              card_num: this.card_num,
            },
          }).then((res) => {
            if (res.data.code === 200) {
              this.$toast.success({
                message: res.data.data.msg,
                forbidClick: true,
                duration: 0,
              });
              setTimeout(()=>{
                location.reload()
              },1000)
            } else {
              this.$toast(res.data.data.msg)
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less">
.ic {
  height: 100vh;
  width: 100vw;
  background: #fff;
  .ic-add {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #3dc48d;
    margin-bottom: 21px;
    .iconfont {
      height: 18px;
      width: 18px;
      margin-right: 6px;
    }
  }
  .ic-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .img {
      height: 80px;
      width: 90px;
      margin-bottom: 16px;
    }
    .tip {
      font-size: 14px;
      line-height: 20px;
      color: #999;
    }
  }
  .ic-info {
    height: 100%;
    padding: 12px 12px 30px;
    .add-box {
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f4f4f4;
      border-radius: 4px;
      margin-bottom: 16px;
    }
    .ic-add {
      margin-bottom: 0;
    }
    .ic-item {
      position: relative;
      height: 96px;
      width: 100%;
      padding: 25px 14px;
      background: url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/%E7%BB%84%20879%403x.png)
        no-repeat center center;
      background-size: 100% 100%;
      border-radius: 4px;
      margin-bottom: 16px;
      &:nth-child(2n) {
        background: url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/%E7%BB%84%20880%403x.png)
          no-repeat center center;
        background-size: 100% 100%;
      }
      .card {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #fff;
        margin-bottom: 8px;
      }
      .time {
        font-size: 12px;
        line-height: 17px;
        color: #fff;
      }
      .unbind {
        position: absolute;
        top: 25px;
        right: 14px;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}
.unbind-msg {
  font-size: 14px;
  line-height: 25px;
  .card {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
  .num {
    color: #3dc48d;
  }
}

.card-dialog {
  padding:0 18px;
  .title {
    margin: 40px 0 4px;
    font-size: 14px;
    line-height: 20px;
    color: #333;
  }
  .card {
    height: 44px;
    width: 100%;
    margin-bottom: 40px;
    background: #FFF;
    border: 1px solid #E3E3E3;
    outline: none;
    -webkit-appearance:none;
    padding: 0 17px;
    font-size: 14px;
    line-height: 44px;
    color: #333;
  }
}
</style>